<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <h1><b-icon icon="arrow-down-up" /> Mis trámites</h1>
        </div>
        <div class="card-body">
          <div class="container col-12">
            <div>
              <b-row>
                <b-col></b-col>
                <b-col class="mb-3">
                  <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                    <b-input-group-prepend is-text>
                      <b-icon icon="search"></b-icon>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="keyword"
                      placeholder="Buscar"
                      type="text"
                    />
                  </b-input-group>
                </b-col>
              </b-row>
              <b-table
                striped
                responsive
                stacked="lg"
                :items="items"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                :keyword="keyword"
              >
                <template #cell(date)="row">
                  <p v-text="fecha(row.item.date)" />
                </template>
                <template #cell(checking)="row">
                  <b-badge pill :variant="returnColor(row.item.checking)">{{
                    returnData(row.item.checking)
                  }}</b-badge>
                </template>
                <template #cell(actions)="row">
                  <ProcedureView :id="row.item.id" />
                </template>
              </b-table>
              <b-pagination
                v-show="rows > perPage"
                style="display: flex; justify-content: center"
                v-model="currentPage"
                pills
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProcedureView from "./Procedure-view.vue";
export default {
  components: { ProcedureView },
  data: () => ({
    selected: "",
    keyword: "",
    perPage: 10,
    currentPage: 1,
    fields: [
      { key: "name", label: "Nombre" },
      { key: "date", label: "Fecha y hora" },
      { key: "checking", label: "Revisión" },
      { key: "actions", label: "Acciones" },
    ],
    desserts: [],
  }),

  created() {
    this.initialize();
  },
  computed: {
    rows() {
      return this.desserts.length;
    },
    items() {
      this.currentPage = 1;
      return this.keyword
        ? this.desserts.filter(
            (item) =>
              item.name.toLowerCase().includes(this.keyword.toLowerCase()) ||
              item.date.toLowerCase().includes(this.keyword.toLowerCase())
          )
        : this.desserts;
    },
  },

  methods: {
    fecha(date) {
      return date.substring(0, 10) + " (" + date.substring(11, 16) + ")";
    },
    returnData(data) {
      if (data === true) return "Finalizado";
      else if (data === false) return "Pendiente";
    },
    returnColor(data) {
      if (data === true) return "primary";
      else if (data === false) return "";
    },
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/my-procedures", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          var aux = [];
          for (var i in res.data) {
            aux.push(res.data[i]);
          }
          this.desserts = aux;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style scoped>
.card-header {
  background-color: #1d425e;
  color: white;
}
</style>