<template>
  <div id="ProcedureView">
    <b-icon-eye
      type="button"
      font-scale="1.2"
      v-b-popover.hover.left="'Ver trámite'"
      @click="viewItem"
    ></b-icon-eye>
    <div>
      <b-modal
        scrollable
        centered
        v-model="show"
        title="Información de trámite"
      >
        <b-container fluid>
          <!--Contenido modal-->
          <div v-if="desserts.observes.length != 0">
            <b-alert show variant="danger">
              <h5 class="alert-heading">Observación</h5>
              <label v-for="(dato, index) in desserts.observes" :key="index"
                ><b>* {{ dato.content }}</b></label
              >
            </b-alert>
          </div>
          <!--Información-->
          <div>
            <div v-if="desserts.states.length != 0">
              <b-alert show :variant="returnState(desserts.checking)">
                <h5 class="alert-heading">Estados</h5>
                <div v-if="desserts.checking == false">
                  <b-badge variant="" class="ticket-role"> Pendiente </b-badge>
                </div>
                <b-badge
                  :variant="returnColor(state.color)"
                  class="ticket-role"
                  v-for="(state, index) in desserts.states"
                  :key="index"
                >
                  {{ state.name }}
                </b-badge>
              </b-alert>
            </div>
            <div v-if="desserts.data.length != 0">
              <b-alert show :variant="returnState(desserts.checking)">
                <h5 class="alert-heading">Datos</h5>
                <div v-for="(dato, index) in desserts.data" :key="index">
                  <div v-if="dato.type == '#' && dato.value != null">
                    <label>
                      <b
                        >{{ dato.name }}:
                        <a
                          :href="
                            $urlFirebase +
                            dato.value +
                            '?alt=media'
                          "
                          target="_blank"
                        >
                          <b-icon type="button" icon="link45deg" /></a></b
                    ></label>
                  </div>
                  <label v-else
                    ><b>{{ dato.name }}:</b> {{ dato.value }}</label
                  >
                </div>
              </b-alert>
            </div>
          </div>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="exit()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Aceptar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProcedureView",
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      desserts: { data: [], observes: [], states: [] },
    };
  },
  methods: {
    returnState(data) {
      if (data) return "success";
      else return "secondary";
    },
    returnColor(data) {
      switch (data) {
        case "green":
          return "success";
        case "red":
          return "danger";
        case "blue":
          return "primary";
        default:
          return "";
      }
    },
    exit() {
      this.show = false;
    },
    async viewItem() {
      this.show = true;
      await this.$axios
        .get("/my-procedures/view?id=" + this.id, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
.ticket-role {
  font-size: 15px;
  font-weight: 700;
  line-height: 1;
  margin: 3px;
  display: inline-block;
}
</style>